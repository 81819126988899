<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/manage/paper/list/${paperBankId}` }">试卷列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ paperName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <div></div>
      <div class="yt-header-toolbar">
        <el-button type="primary" size="small" style="width: 80px" @click="editPaperDetail" v-if="isOwner">
          <div>
            <YTIcon :href="'#icon-caozuobianji'" />
            <p>编辑</p>
          </div>
        </el-button>
        <el-button size="small" style="width:136px" @click="toDetailPaperList">
          <div>
            <YTIcon :href="'#icon-fanhui'" />
            <p>返回试卷列表</p>
          </div>
        </el-button>
      </div>
    </div>
    <div class="yt-container-info" v-loading="loading" element-loading-text="加载中">
      <div class="check-right">
        <div :class="{ 'right-drawer': drawer }">
          <YTIcon :href="'#icon-shouqi'" @click="drawer = false" v-if="drawer" />
          <div class="header" v-if="drawer">
            <p>
              试卷总量<span style=" color: #448BFF;margin-right: 2px"> {{ paperInfo[2] }} </span>题
            </p>
          </div>
          <div class="content" v-if="drawer">
            <div class="content-list" v-for="(item, index) in questionList" :key="index">
              <div class="content-info">
                <div class="type"></div>
                <p>{{ item.name }}</p>
                <el-input
                  maxLength="3"
                  v-if="isOwner"
                  oninput="value=value.replace(/[^\d.]/g,'')"
                  style="width: 45px;margin-right: 5px"
                  v-model="item.totalPoints"
                  @keydown.enter.native="setBigTopicPoint(item.totalPoints, index)"
                ></el-input>
                <p v-else>{{ item.totalPoints }}</p>
                <p>分</p>
              </div>
              <div class="grid-container">
                <div
                  class="square"
                  :key="index"
                  v-for="(item, index) in questionList[index].questionVOS"
                  :style="{ marginRight: (index + 1) % 5 === 0 ? '0' : '10px' }"
                  :class="{
                    'preview-question-item': true,
                    current: questionId === item.questionId
                  }"
                  @click="handleClickQuestion(item.questionId)"
                >
                  {{ index + 1 }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 100%; display: flex; align-items: center; justify-content: center">
          <YTIcon :href="'#icon-shouqi'" @click="drawer = true" v-if="!drawer" />
        </div>
      </div>
      <div class="yt-content-info">
        <div class="paper-answer-list">
          <div class="paper-name">{{ paperName }}</div>
          <div class="bottom">
            <div v-for="(item, index) in paperInfoList" :key="index" class="item">
              <div style="display: flex;flex-flow: row">
                <YTIcon :href="item.icon"></YTIcon>
                <p style="margin-left: 5px">{{ item.label }}{{ paperInfo[index] }}{{ item.item }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="yt-question-info">
        <div class="yt-question-content">
          <div v-for="(item, index) in questionList">
            <div class="question-sort">
              <p>{{ item.name }}</p>
              <p style="color: #448BFF">{{ item.questionAmount }}</p>
              <p>题</p>
              <el-input
                maxLength="3"
                v-if="isOwner"
                style="margin-right: 5px"
                oninput="value=value.replace(/[^\d.]/g,'')"
                v-model="item.totalPoints"
                @keydown.enter.native="setBigTopicPoint(item.totalPoints, index)"
              ></el-input>
              <p v-else>{{ item.totalPoints }}</p>
              <p>分</p>
            </div>
            <template v-if="questionList.length > 0">
              <div v-for="(question, index) in questionList[index].questionVOS" :key="index" class="item" :id="'anchor-' + question.questionId">
                <div style="width: 100%">
                  <!--题目卡片头部-->
                  <div class="header">
                    <div class="question-type">
                      {{ ytConstant.questionType.getLabel(question.questionType) }}
                    </div>
                    <p class="audit">
                      <YTIcon :href="auditIcons[question.status]"></YTIcon>
                    </p>
                  </div>
                  <div class="body">
                    <!--题干-->
                    <div class="stem">
                      {{ index + 1 }}.&nbsp;
                      <div v-html="question.stem"></div>
                    </div>
                    <MonacoEditor
                      v-if="question.hasCodeStem"
                      class="stem-code"
                      :editorId="question.id + question.codeStem.codeType + index"
                      :value="question.codeStem.template"
                      :language="codeTypes[question.codeStem.codeType]"
                    ></MonacoEditor>
                    <component :is="questionComponents[question.questionType]" :question="question" style="margin-top: 16px"></component>
                    <div class="correct-answer">
                      <div style="flex-shrink: 0;letter-spacing: 1px">正确答<span style="letter-spacing: 5px">案:</span></div>
                      <div v-html="ytConstant.answerType.getLabel(question)"></div>
                    </div>
                    <div style="display: flex">
                      <div style="flex-shrink: 0">
                        <span style="letter-spacing: 12px">解</span>
                        <span style="letter-spacing: 5px">析:</span>
                      </div>
                      <div v-html="question.analysis"></div>
                    </div>
                    <div class="tag-and-knowledge" ref="tagAndKnowledge">
                      <div class="tk-item tk-select-item">
                        <!--                        <span style="letter-spacing: 12px">标</span>-->
                        <!--                        <span style="letter-spacing: 5px">签:</span>-->
                        <!--                        <template v-for="(tag, tIndex) in question.tags">-->
                        <!--                          <div :key="tIndex">-->
                        <!--                            <el-tooltip effect="dark" :disabled="tag.name.length < 4" :content="tag.name" placement="top" :open-delay="1000">-->
                        <!--                              <div class="tk-point" :key="tIndex" v-if="tIndex < 5">-->
                        <!--                                {{ tag.name }}-->
                        <!--                              </div>-->
                        <!--                            </el-tooltip>-->
                        <!--                          </div>-->
                        <!--                        </template>-->
                        <span style="letter-spacing: 12px">知</span>
                        <span style="letter-spacing: 12px">识</span>
                        <span style="letter-spacing: 5px">点:</span>
                        <template v-for="(tag, tIndex) in question.knowledgePoints">
                          <div :key="tIndex">
                            <el-tooltip
                              effect="dark"
                              :disabled="tag.knowledgeName.length < 4"
                              :content="tag.knowledgeName"
                              placement="top"
                              :open-delay="1000"
                            >
                              <div class="tk-point" :key="tIndex" v-if="tIndex < 5">
                                {{ tag.knowledgeName }}
                              </div>
                            </el-tooltip>
                          </div>
                        </template>
                        <el-popover
                          placement="top"
                          width="202"
                          trigger="hover"
                          title="题目标签"
                          v-if="question.tags !== null && question.tags.length > 5"
                        >
                          <div style="margin-bottom: 5px" class="tab-list">
                            <template v-for="(tagPop, tPopIndex) in question.tags">
                              <div :key="tPopIndex">
                                <el-tooltip
                                  effect="dark"
                                  :disabled="tagPop.name.length < 4"
                                  :content="tagPop.name"
                                  placement="top"
                                  :open-delay="1000"
                                >
                                  <div class="tk-point" :key="tPopIndex" v-if="tPopIndex >= 5" style="margin-bottom: 5px">
                                    {{ tagPop.name }}
                                  </div>
                                </el-tooltip>
                              </div>
                            </template>
                          </div>
                          <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px"></YTIcon>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                  <!--题目卡片底部-->
                  <div class="footer">
                    <div>
                      <p class="inactive">难度系<span style="letter-spacing: 5px">数:</span>{{ question.difficulty / 10 }}</p>
                      <p>
                        {{ ytConstant.difficultType.getLabel(question.difficulty) }}
                      </p>
                      <p slot="reference" class="popover-reference">使用次<span style="letter-spacing: 5px">数:</span>{{ question.useCount }}</p>
                      <p slot="reference" class="popover-reference" style="display: flex;flex-flow: row" v-if="isOwner">
                        设置分<span style="letter-spacing: 5px">数:</span
                        ><el-input
                          oninput="value=value.replace(/[^\d.]/g,'')"
                          v-model="question.points"
                          onkeyup="let v=this.value||'';if(v<1&&v!==''){this.value=0.5;}else if(v>=1&&v.toString().indexOf('.')!==-1){this.value=Math.floor(v);} "
                          maxLength="3"
                          @input="setSmallTopicPoint(question.questionId, question.points, question.sectionId)"
                          @keydown.enter.native="setTopicPoints"
                        ></el-input>
                      </p>
                      <div v-else>
                        <p>分数：</p>
                        <p>{{ question.points }} 分</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import TrueOrFalse from '../../question/list/questions/TrueOrFalse'
import Choice from '../../question/list/questions/Choice'
import Code from '@components/manage/question/list/questions/Code'
import questionApi from '@api/question'
import paperApi from '@api/paper'
import MonacoEditor from '@components/common/MonacoEditor'
export default {
  name: 'PaperDetail',
  components: { YTIcon, MonacoEditor, Code },
  data() {
    return {
      loading: false,
      questionComponents: [TrueOrFalse, Choice, Choice, null, null, Code], //题目组件
      drawer: false,
      point: '',
      paperBankName: '',
      questionId: '',
      paperName: '', //试卷名称
      pageNum: 0,
      pageSize: 5,
      total: 0,
      paperBankId: '',
      isOwner: false,
      paperId: this.$route.query.id,
      questionList: [],
      questionPoints: [],
      paperInfoList: [
        {
          label: '创建时间：',
          icon: '#icon-daojishi',
          item: ''
        },
        {
          label: '试卷总分：',
          icon: '#icon-shitixiangqing',
          item: '分'
        },
        {
          label: '试题总量：',
          icon: '#icon-fenlei',
          item: '题'
        }
      ],
      paperInfo: [],
      auditIcons: ['#icon-yishenhe', '#icon-caogao', '#icon-weishenhe'] //审核图标
    }
  },
  created() {
    this.paperBankName = sessionStorage.getItem('paperBankName')
    this.getPaperInfo()
  },
  methods: {
    handleClickQuestion(type) {
      this.questionId = type
      this.$el.querySelector('#anchor-' + type).scrollIntoView({
        behavior: 'smooth' // 平滑过渡
      })
    },
    toDetailPaperList() {
      this.$router.push({
        path: `/manage/paper/list/${this.paperBankId}`
      })
    },
    editPaperDetail() {
      this.$router.push({
        path: `/manage/paper/edit`,
        query: {
          paperId: this.paperId,
          paperBankId: this.paperBankId
        }
      })
    },
    countPaperTotal() {
      let total = 0
      this.questionList.forEach(item => {
        total += item.totalPoints
      })
      this.paperInfo[1] = total
    },
    //设置大题分数
    setBigTopicPoint(item, index) {
      let point = Math.floor(item / this.questionList[index].questionAmount) === 0 ? 0.5 : Math.floor(item / this.questionList[index].questionAmount)
      this.questionList[index].questionVOS.forEach(item => {
        item.points = point
        this.questionPoints.push({
          points: point,
          questionId: item.questionId
        })
      })
      this.questionList.forEach(item => {
        let total = 0
        item.questionVOS.forEach(item => {
          total += parseFloat(item.points)
        })
        item.totalPoints = total
      })
      this.countPaperTotal()
      this.setTopicPoints()
    },
    //设置小题分数
    setSmallTopicPoint(questionId, point) {
      let points = point == 0 || point < 1 ? 0.5 : point
      if (this.questionPoints.length !== 0) {
        this.questionPoints.forEach((item, index) => {
          if (item.questionId === questionId) {
            this.questionPoints.splice(index, 1)
          }
        })
      }
      this.questionPoints.push({ points: points, questionId: questionId })
      this.questionList.forEach(item => {
        let total = 0
        item.questionVOS.forEach(item => {
          let v = item.points
          if (v < 1 || v === '') {
            v = 0.5
          } else if (v > 1 && v.toString().indexOf('.') !== -1) {
            v = Math.floor(v)
          }
          total = total + parseFloat(v)
        })
        item.totalPoints = total
      })
      this.countPaperTotal()
    },
    getPaperInfo() {
      this.loading = true
      paperApi
        .getAllPaperInfoById(this.paperId)
        .then(res => {
          this.paperBankId = res.res.paperBankId
          this.paperName = res.res.name
          this.paperInfo.push(this.$formatTime(res.res.createTime, 'yyyy-MM-dd'))
          this.paperInfo.push(res.res.totalPoints)
          this.paperInfo.push(res.res.questionAmount)
          this.paperId = res.res.paperId
          this.isOwner = res.res.isOwner
          this.questionList = res.res.sectionVOS
        })
        .finally(() => {
          this.loading = false
        })
    },
    setTopicPoints() {
      questionApi.modifyQuestionPoint(this.paperId, this.questionPoints).then(res => {
        if (res.code === 0) {
          this.$message.success('设置分数成功')
          this.questionPoints = []
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
@import '~@/theme/paperDetail';
::v-deep .el-input__inner {
  height: 20px;
  border: 1px solid #d9d9d9;
  padding: 0 3px;
}
//题库列表悬停标签
.tab-list {
  display: flex;
  flex-wrap: wrap;
}
.tk-point {
  max-width: 70px;
  height: 22px;
  line-height: 22px;
  border-color: #edf4ff;
  border-radius: 2px;
  color: #448bff;
  margin-right: 8px;
  padding: 0 8px;
  background-color: #edf4ff;
  font-size: @small;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  i {
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
  }
  &:focus {
    border-color: #448bff;
  }
}
.yt-main {
  padding-top: 0;
}
.yt-container-info {
  background-color: #f3f4f7;
  padding: 10px;
  width: 100%;
  height: calc(100% - 48px);
  position: relative;
  .yt-content-info {
    background-color: #ffffff;
    padding: 0;
  }
}
.content-list {
  &:last-child .grid-container {
    border: 0;
  }
}
</style>
